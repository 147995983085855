import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_no', 
          label: 'เลขที่ใบกำกับภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'ref_no', 
          label: 'อ้างอิง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'supplier_code', 
          label: 'รหัสผู้ขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'supplier_name', 
          label: 'ชื่อผู้ขาย',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'qty', 
          label: 'จำนวน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'type', 
          label: 'ประเภทเอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2', 
        },
        {
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'PO-201665156',
          doc_date: new Date(),
          inv_no: 'IN-202221513',
          supplier_code: 'S-001',
          supplier_name: 'บ.สมชาย จำกัด',
          type: 'เบิก',
          qty: 2,
          nettotal: 2000,
          status: '<label class="badge badge-info w-100">On hold</label>'
        },
        {
          _id: '123455',
          doc_no: 'PO-201665154',
          doc_date: new Date(),
          inv_no: 'IN-202221514',
          supplier_code: 'S-001',
          supplier_name: 'บ.สมชาย จำกัด',
          ref_no: 'CN-2022165465',
          type: 'ส่งคืน',
          qty: 2,
          nettotal: 2000,
          status: '<label class="badge badge-info w-100">On hold</label>'
        },
        {
          _id: '123457',
          doc_no: 'PO-201665157',
          doc_date: new Date(),
          inv_no: 'IN-202221515',
          supplier_code: 'S-001',
          supplier_name: 'บ.สมชาย จำกัด',
          type: 'ขาย',
          qty: 1,
          nettotal: 1000,
          status: '<label class="badge badge-info w-100">On hold</label>'
        },
      ],
      status: null,
      orderStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'รออนุมัติ', value: 'wait-approve' },
        { text: 'อนุมัติแล้ว', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' },
        { text: 'สำเร็จ', value: 'success' },
        { text: 'ยกเลิก', value: 'cancel' }
      ],
      type: null,
      orderTypes: [
        { text: 'ขาย', value: 'sale' },
        { text: 'ส่งคืน', value: 'return' },
        { text: 'เบิก', value: 'withdraw' },
        { text: 'ยกเลิก', value: 'cancel' }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}